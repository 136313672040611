import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import hero from '../../images/issues/public-safety/public-safety-hero.webp'
import accomplishment1 from '../../images/issues/public-safety/public-safety-accomplishment-1.webp'
import accomplishment2 from '../../images/issues/public-safety/public-safety-accomplishment-2.webp'
import accomplishment3 from '../../images/issues/public-safety/public-safety-accomplishment-3.webp'
import accomplishment4 from '../../images/issues/public-safety/public-safety-accomplishment-4.webp'

const IssuesPublicSafetyPage = () => (
  <Layout>
    <Seo title="Issues - Public Safety" />
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 xl:px-0">
        <div>
          <img
              className="w-full max-h-96 object-cover object-pos-0-40" // TODO: figure out aspect ratio -- trying to set 2:1
              src={hero}
              alt=""
            />
        </div>
        <div className="max-w-5xl mx-auto"> {/* figure out Tailwind CSS typography plugin later */}
          <h1 className="pt-10 font-bold text-6xl">Public Safety</h1>
          <p className="pt-5 text-2xl leading-relaxed">
            Every neighborhood in Providence can flourish if public safety becomes a priority across the city. Every resident deserves sufficient access to health services, youth development and educational opportunities, support for trauma, and anti-violence efforts on a community level. Public safety matters in schools, workplaces, and on the streets. Achieving these goals must involve a high degree of community trust and justice that focuses on restoring and rebuilding the communities most affected.
          </p>
          <p className="pt-5 text-2xl leading-relaxed">
            Councilman Goncalves has prioritized criminal justice reform, crime prevention, and policing approaches that are data-driven. As a top priority, it is crucial to provide the resources and support the Chief needs to shape the culture of the police department, respond effectively, and increase accountability. However, our safety is not the responsibility of only the police. With the resources and attention of the local government, Councilman Goncalves seeks to address crime at its root causes and strengthens its overall effort to prevent crime. We must invest record amounts in our youth, affordable housing, and economic policies to make Providence a safer and more inclusive city.
          </p>
          <p className="pt-5 text-2xl leading-relaxed">
            Gun violence is creating a perception that we are not a safe city and moving backward on crime. It's time to restore public faith and make the city feel safe. We need to fix what's not working.
          </p>
          <p className="pt-5 text-2xl leading-relaxed">
            As such, keeping people safe cannot be accomplished in the same old way, by investing in piecemeal solutions despite the bigger picture. A holistic vision, including practical steps to be taken, is the basis of my plan for real safety. Leaders who have the courage to invest in results, not the status quo, have the ability to find what really works.
          </p>
          <p className="pt-5 text-2xl leading-relaxed">
            Like all city departments, we need to do everything that we can to strengthen, enhance and improve the police department's ability to truly serve and protect the community. To solve the current crisis of violence, we must take a multi-pronged approach, including the right staffing level for 911 response and investigation. Despite the importance of police, programs such as the co-responder program are also vital.
          </p>
          <p className="pt-5 text-2xl leading-relaxed">
            Responders must be appropriately trained for mental health and drug abuse crisis calls. These are not criminal issues; they are human issues and as such, they need to be treated accordingly.
          </p>
          <p className="pt-5 text-2xl leading-relaxed">
            We must understand we are surrounded by many people in need of support. There are too many children in our city who don't get the support they need in order to succeed. Youth need more after-school programs, drop-in centers, and mentorship services.
          </p>
          <p className="pt-5 text-2xl leading-relaxed">
            In my opinion, we need a both/and approach to public safety and community accountability. Both police and social services need to be funded adequately if we wish to create opportunities for all.
          </p>
          <p className="pt-5 text-2xl leading-relaxed">
            Through the development and maintenance of trustful relationships, we will increase peace and safety in the community and further engage and meet the needs of those who need our support the most.
          </p>

          <h2 className="pt-10 font-bold text-4xl">Our Vision</h2>
          <p className="pt-5 text-2xl leading-relaxed">• Ensure that public safety respects the dignity of every individual.</p>
          <p className="pt-5 text-2xl leading-relaxed">• The residents of Providence, regardless of their racial, ethnic, religious, economic, or physical status, deserve to feel safe in their homes and in the city at large.</p>
          <p className="pt-5 text-2xl leading-relaxed">• People of color, particularly Black, Brown, Asian, and Indigenous peoples, and immigrants should be able to access the government's public safety functions at the same level as those in the most privileged neighborhoods. We can, together, develop a public safety and emergency response system for our city designed for today's needs -- one that promotes health and well-being, stops and interrupts violence, and prevents crime, as well as a PPD that is transparent and accountable to every part of the community it serves.</p>

          <h2 className="pt-10 font-bold text-4xl">Key Pillars Of Building Community Safety</h2>
          <p className="pt-5 text-2xl leading-relaxed"><strong>Financial Security</strong> - We must invest in all residents' economic security in order to strengthen our City's safety.</p>
          
          <p className="pt-5 text-2xl leading-relaxed"><strong>A holistic approach</strong> - We should develop and invest greatly in a Division of Violence Prevention, which will bring together the police, the fire department, emergency management and private-public partnership violence prevention agencies such as the <a href="https://www.nonviolenceinstitute.org/" target="_blank">Nonviolence Institute</a> under one unified structure.</p>

          <p className="pt-5 text-2xl leading-relaxed"><strong>Invest in Young Men</strong> - Launch an initiative to create a citywide curriculum, mentorship, wraparound services, and employment opportunities for young men, particularly young Black and LatinX men, ages 10-26.</p>

          <p className="pt-5 text-2xl leading-relaxed"><strong>Help Unbundle Providence Police Department</strong> - "Unbundle" policing, and make Providence Police an agency with the capability of efficiently investigating serious crimes and enforcing the law.</p>

          <p className="pt-5 text-2xl leading-relaxed"><strong>Active Leadership</strong> - Using the bully pulpit of the office as a role model on violence prevention and intervention, public safety, brings together the best and brightest to tackle our most challenging problems.</p>
        </div>
        <div className="max-w-5xl mx-auto pt-10">
          <span className="text-base tracking-widest uppercase">John's Record</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl pb-10">What We've Accomplished Together</h2>
          {/*TODO: L side - bullet point, R side - image - TEXT John when I'm working on this, Sanctuary, John visiting Boston - Mass & Cass, etc.*/}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <div>
              <h3 className="font-bold text-xl">Strengthened Communication and Engagement Between the Police Department and Our Community</h3>
              <p>• Held and hosted Ward 1 monthly community meetings with district lieutenants and patrol officers to strengthen the relationship, build empathy and understanding between the police department and our community. Ensured consistent monthly community meetings and more robust accountability and communication among the police department and our greater community. Supported a community policing model that relies on collaborative partnerships, developing proactive and preventative solutions, and increasing trust in public safety officials.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment1}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Fought to expand a crisis-response team to field 911 calls involving mental health and substance abuse</h3>
              <p>• In September 2020, at the urging of members like myself on the Council, the City of Providence engaged PFM's Center for Justice and Safety Finance to conduct a <a href="https://www.providenceri.gov/wp-content/uploads/2021/04/City-of-Providence-Dept-of-Public-Safety-Budget-Analysis-04.13.2021.pdf" target="_blank">comprehensive review and analysis</a> of our Public Safety operations. The review determined that more than half of calls initiating a police response in recent decades were for noncriminal, nonviolent matters. This helped inform how traditional public safety services (police, fire, and EMS) could be restructured to shift Providence to a prevention-first model of public safety designed to create a healthier, safer, and more just Providence all while improving services and outcomes for residents.</p>

              <h3 className="font-bold text-xl mt-5">Advocated for over $1 million additional dollars and counting to diversion programs over the last two fiscal years</h3>
              <p>Supported FYI 2022-2022 allocation of $721,000 for a program to move certain 911 calls to social services and worked with colleagues to urge leadership to include this allocation into the city budget. 

              Additionally, advocated for fiscal year 2021-22 budget allocation to spend $600,000 on a pilot program to study diverting certain mental health and social service emergency calls away from the police.

              We must build upon these investments and we must continue to leverage resources into diversion and crisis-response programs that build upon the department's existing work.</p>

              <h3 className="font-bold text-xl mt-5">Supported PPD's Partnership with The Providence Center and Family Service of Rhode Island and “Go Team” Diversionary and Crisis Intervention Programs</h3>
              <p>Have been a staunch supporter of The Providence Police Go Team which is a direct service partnership between the PPD and Family Service of Rhode Island (FSRI). The Go Team is a diversionary program in which clinicians ride along with police officers to respond to individuals in psychiatric or substance use crises. Clinicians de-escalates the situation and conduct a clinical assessment on-site to ensure that people in need get the appropriate safety and care. This model has also increased the ability to provide crisis intervention services when police response is not required.  In efforts to meet the needs of child victims and witnesses FSRIs trauma-trained, bilingual social workers responded on-scene with law enforcement as a “Go Team”, offering immediate crisis intervention and stabilization, safety planning, language support.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment2}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Advocated for Common-Sense Gun Laws To Curb The Follow of Illegal Guns In Providence</h3>
              <p>• As Chair of State Legislative Affairs on the Providence City Council, Goncalves worked closely with the General Assembly to urge passage of more robust policy proposals to curb the flow of illegal guns into Providence communities.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment3}
                alt=""
              />
            </div>
            <div>
              <h3 className="font-bold text-xl">Sought to Improve Public Safety and Diversity The Police Force To Greater Reflect The Community</h3>
              <p>• Strengthening public safety requires improving Providence's approach to policing by securing the data, oversight, and accountability measures necessary to make intelligent, effective changes to move toward a public safety system that works for all. Goncalves has been an advocate for putting an end to overcriminalization. He has also been an advocate for additional training, in which staff members are given further instruction on de-escalation, use of force, implicit bias, and tools for reporting inappropriate behavior by colleagues. Goncalves has also been a strong proponent for diversifying recruitment and helping the PD recruit more people of color, women, and LGBTQ+ members to ultimately ensure that the department reflects the community it serves.</p>
            </div>
            <div>
              <img
                className="w-full max-h-96 object-cover" // TODO: figure out aspect ratio -- trying to set 2:1
                src={accomplishment4}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="max-w-5xl mx-auto pt-10 pb-10">
          <span className="text-base tracking-widest uppercase">Our Policy Platform Moving Forward</span> {/* Is "span" the right tag for eyebrows?  Maybe? */}
          <h2 className="font-bold text-5xl">The Work We Still Have To Do</h2>

          <h3 className="font-bold text-3xl mt-5">Focusing on Public Health for Improved Public Safety</h3>
          <p className="text-xl">All across the country and in Providence, racial injustice has become the focus of new initiatives and projects to transform public safety into public health.</p>
          <p className="text-xl">The city needs a new direction for the allocation of funds and the development of plans to respond to serious and traumatic situations. We need to make resource access easier and coordinate all agencies for a maximum positive effect. This includes the redirection of 911 calls concerning mental health problems and the homeless to public health experts. It also means improving communication and access to hospitals, improving how groups share public health information, and streamlining service infrastructure.</p>

          <h3 className="font-bold text-3xl mt-5">Youth Support and Services</h3>
          <p className="text-xl">Sufficient and realistic opportunities help destroy the community factors that lead to violence and increased crime. Investing in current safety and future success will go a long way to improving this great city. Investing more dollars into school-year opportunities and summertime employment options can help. Also, we need to give the youth a voice to make sure they have a hand in shaping their own futures. We suggest that removing all police forces from the Providence Public School system is a positive first step. Offering additional services such as counselors and trauma therapists will also improve public health overall.</p>

          <h3 className="font-bold text-3xl mt-5">Stand Against Community Violence</h3>
          <p className="text-xl">Every resident of Providence deserves safety from violence and sufficient and appropriate support services to help. We believe that the public safety structures should offer all necessary benefits to people affected by gun violence, domestic violence, LGBTQIA+ and non-binary-focused violence, and other hate crimes. Social health services must include more than medical care. Appropriate counseling, mental health care, and survivor services are a necessary part of ensuring everyone's security.</p>

          <h3 className="font-bold text-3xl mt-5">Preventing Hate Crimes</h3>
          <p className="text-xl">The rate of hate crimes in our country has gone up in the past several years. This includes all types of criminal activity that specifically targets people of color, the LGBTQIA+ community, immigrants, Muslim and Jewish people, and others. Preventing these crimes matters, but we also must improve services for survivors. </p>
          <p className="text-xl">Introducing more multilingual and culturally sensitive service providers will improve the level of care they receive. The system must take a firm stand against discriminatory ideologies and actively fight against hate, improve communication between groups, and work equally for all communities in our city.</p>
            
          <h3 className="font-bold text-3xl mt-5">A Focus on True Causes of Criminalization</h3>
          <p className="text-xl">The prevention of both violent and nonviolent crime is of importance to all residents. Communities of color and those in lower socioeconomic brackets are especially susceptible to the traumatic truth of criminalization. We need to address the underlying causes, offer services to help recovery from untreated trauma, and support systems that break cycles of violence in our city. This includes dismantling justice and legal systems that currently reinforce systemic racial injustice. Improving or negating issues that lead to unfairness and inequality, such as housing and food insecurity, transit inequality, environmental factors, and excessive incarceration are necessary for overall public safety. It is time to address these issues holistically by improving public health and managing funds intelligently and investing to improve the foundation of education, healthcare, housing, and basic human rights.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export default IssuesPublicSafetyPage
